<template>
  <div>
    <div class="guide-page-box">
      <div v-if="route.query.type == '3'">
        <img
          class="width-and-height"
          :src="
            require('../../assets/img/guidePage/business/businessTitle.svg')
          "
        />
        <div class="business-step mt12 ml15 mr15">
          <customerTitle title="Step1" />
          <span class="text-class mt8">
            在“消息”-“客户联系”或“客户群”收到待发送的企业消息
          </span>
          <img
            class="width-and-height mt8"
            :src="
              require('../../assets/img/guidePage/business/businessOne.svg')
            "
          />
        </div>
        <div class="business-step mt24 ml15 mr15">
          <customerTitle title="Step2" />
          <span class="text-class mt8">进入聊天框，点击“任务”消息</span>
          <img
            class="width-and-height mt8"
            :src="
              require('../../assets/img/guidePage/business/businessTwo.svg')
            "
          />
          <span class="business-two">端午节营销活动全场9折</span>
        </div>
        <div class="business-step mt24 ml15 mr15">
          <customerTitle title="Step3" />
          <span class="text-class mt8">确认消息后，点击“确认”</span>
          <img
            class="width-and-height mt8"
            :src="
              require('../../assets/img/guidePage/business/businessThree.svg')
            "
          />
          <span class="business-three">端午节营销活动全场9折</span>
        </div>
      </div>
      <div v-if="route.query.type == '1'">
        <div class="member-from mt12 ml15">
          操作顺序：点击“去完成”，跳转客户聊天框
        </div>
        <div class="member-first mt21 ml15 mr15">
          <div class="member-first-box">
            <customerTitle title="第1步" />
            <span class="member-fist-text mt9">
              再点击“快捷发送”， 发送对应的任务
            </span>
          </div>
          <img
            class="member-img-col mt40 ml26"
            :src="
              require('../../assets/img/guidePage/member/member-first-col.svg')
            "
          />
          <img
            class="member-img-circle"
            :src="
              require('../../assets/img/guidePage/member/member-first-circle.svg')
            "
          />
        </div>
        <div class="member-second mt21 ml15 mr15">
          <div class="member-second-box mt27">
            <customerTitle title="第2步" />
            <span class="member-second-text mt9">
              找到右侧对应的任务，点击“发送”
            </span>
          </div>
          <img
            class="member-img-col-two"
            :src="
              require('../../assets/img/guidePage/member/member-second-col.svg')
            "
          />
          <img
            class="member-img-circle-two"
            :src="
              require('../../assets/img/guidePage/member/member-second-circle.svg')
            "
          />
        </div>
        <div class="member-task mt21">
          <div class="member-send">
            待发送
            <span class="member-send-num">
              ({{ memberData.can_customer_num }})
            </span>
          </div>
          <div style="display: inherit">
            <van-count-down :time="endTime" v-if="endTime > 0">
              <template #default="timeData">
                <span class="task-info-item">任务结束剩余&nbsp;</span>
                <span v-if="timeData.days">
                  <span class="txt-blue member-time-num">
                    {{ timeData.days }}
                  </span>
                  <span class="task-info-item">&nbsp;天&nbsp;&nbsp;</span>
                </span>
                <span v-if="timeData.hours">
                  <span class="txt-blue member-time-num">
                    {{ timeData.hours }}
                  </span>
                  <span class="task-info-item">&nbsp;时&nbsp;&nbsp;</span>
                </span>
                <span v-if="timeData.minutes">
                  <span class="txt-blue member-time-num">
                    {{ timeData.minutes }}
                  </span>
                  <span class="task-info-item">&nbsp;分&nbsp;&nbsp;</span>
                </span>
              </template>
            </van-count-down>
            <div class="member-Refresh" @click="handleRefresh()">
              <img
                :src="require('../../assets/img/guidePage/common/refresh.svg')"
              />
            </div>
          </div>
        </div>
        <div v-if="memberData.touch_object == 'SINGLE'">
          <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
          <van-list
            v-model:loading="loading"
            v-model:error="error"
            :finished="finished"
            :finished-text="
              memberDataList.length == 0 ? '暂无数据' : '没有更多了'
            "
            error-text="请求失败，点击重新加载"
            :immediate-check="false"
            @load="onload"
          >
            <van-cell-group
              v-for="(item, index) in memberDataList"
              :key="index"
            >
              <van-cell>
                <template #default>
                  <div class="member-send-task">
                    <div class="member-send-people flex">
                      <img
                        :src="
                          item.qw_avatar
                            ? item.qw_avatar
                            : 'https://rescdn.qqmail.com/node/wwmng/wwmng/style/images/independent/DefaultAvatar$73ba92b5.png'
                        "
                        class="member-send-task-img"
                      />
                      <div>
                        <div class="custom-title member-send-task-name ml8">
                          {{ item.name }}
                        </div>
                        <div
                          class="custom-title member-send-task-other-name ml8"
                        >
                          昵称：{{ item.nick_name }}
                        </div>
                      </div>
                    </div>
                    <van-button
                      v-if="item.status == '0'"
                      type="primary"
                      size="small"
                      class="member-button"
                      @click="goDo(index)"
                      :disabled="isStart === 0"
                    >
                      去完成
                    </van-button>
                    <div
                      v-else
                      class="text-content mr9"
                      size="small"
                      :disabled="item.status != '0'"
                    >
                      已完成
                    </div>
                  </div>
                </template>
              </van-cell>
            </van-cell-group>
          </van-list>
          <!-- </van-pull-refresh> -->
        </div>
        <div v-else>
          <van-list
            v-model:loading="loading"
            v-model:error="error"
            :finished="finished"
            :finished-text="
              memberDataList.length == 0 ? '暂无数据' : '没有更多了'
            "
            error-text="请求失败，点击重新加载"
            :immediate-check="false"
            @load="onload"
          >
            <van-cell-group
              v-for="(item, index) in memberDataList"
              :key="index"
            >
              <van-cell>
                <template #default>
                  <div class="member-send-task">
                    <div class="custom-title member-send-group">
                      {{ item.name }}
                    </div>
                    <van-button
                      v-if="item.status == '0'"
                      type="primary"
                      size="small"
                      class="member-button"
                      @click="goDo(index)"
                      :disabled="isStart === 0"
                    >
                      去完成
                    </van-button>
                    <div
                      v-else
                      class="text-content mr9"
                      size="small"
                      :disabled="item.status != '0'"
                    >
                      已发送
                    </div>
                  </div>
                </template>
              </van-cell>
            </van-cell-group>
          </van-list>
        </div>
      </div>
      <div v-if="route.query.type == '4'">
        <img
          class="width-and-height"
          :src="
            require('../../assets/img/guidePage/moments/business/businessTitle.svg')
          "
        />
        <div class="business-step mt12 ml15 mr15">
          <customerTitle title="第1步" />
          <span class="text-class mt8">
            在“消息”-“客户朋友圈”收到待发送的客户朋友圈
          </span>
          <img
            class="width-and-height mt8"
            :src="
              require('../../assets/img/guidePage/moments/business/businessOne.svg')
            "
          />
        </div>
        <div class="business-step mt24 ml15 mr15">
          <customerTitle title="第2步" />
          <span class="text-class mt8">
            进入朋友圈，点击右上角“未读红点”消息
          </span>
          <img
            class="width-and-height mt8"
            :src="
              require('../../assets/img/guidePage/moments/business/businessTwo.svg')
            "
          />
        </div>
        <div class="business-step mt24 ml15 mr15">
          <customerTitle title="第3步" />
          <span class="text-class mt8">进入企业通知，点击“发表”</span>
          <img
            class="width-and-height mt8"
            :src="
              require('../../assets/img/guidePage/moments/business/businessThree.svg')
            "
          />
        </div>
      </div>
      <div
        v-if="route.query.type == '5' && memberData.is_tag === 1"
        class="moments"
      >
        <div>
          <div class="moments-member mt14 ml15">
            本次目标客户为
            <span class="moments-send-target">
              {{ memberData.all_customer_num }}
            </span>
            个
          </div>
          <div class="flex mt12 flex-justify-between plr15">
            <div class="moments-send-text">
              <div class="moments-already-send mt12">
                {{ memberData.success_send_num }}
              </div>
              个客户已发送
            </div>
            <div class="moments-send-text ml8">
              <div class="moments-member-send mt12">
                {{ memberData.can_customer_num }}
              </div>
              个客户可发送
              <van-popover v-model:show="showPopover" theme="dark">
                因企微限制，其余{{
                  memberData.success_send_num
                }}个客户需明日才可群发
                <template #reference>
                  <van-icon name="info" />
                </template>
              </van-popover>
            </div>
            <div class="moments-send-text ml8">
              <div class="moments-member-send mt12">
                {{ memberData.all_customer_num }}
              </div>
              个目标客户
            </div>
          </div>
        </div>
        <div class="business-step mt16 ml9 mr9">
          <customerTitle title="第1步" />
          <span class="text-class mt8">选择“可见的客户”</span>
          <img
            class="width-and-height mt8"
            :src="
              require('../../assets/img/guidePage/moments/member/memberOne.svg')
            "
          />
        </div>
        <div class="business-step mt8 ml9 mr9">
          <customerTitle title="第2步" />
          <span class="text-class mt8">进入“可见的客户”，点击“部分可见”</span>
          <img
            class="width-and-height mt8"
            :src="
              require('../../assets/img/guidePage/moments/member/memberTwo.svg')
            "
          />
        </div>
        <div class="business-step mt8 ml9 mr9">
          <customerTitle title="第3步" />
          <span class="text-class mt8">进入“选择客户”，点击“根据标签筛选”</span>
          <img
            class="width-and-height mt8"
            :src="
              require('../../assets/img/guidePage/moments/member/memberThree.svg')
            "
          />
        </div>
        <div class="business-step mt8 ml9 mr9 business-step-four">
          <customerTitle title="第4步" />
          <span class="text-class mt8">
            进入“标签筛选”，选择“{{ tagList }}”，再点击“确认”
          </span>
          <img
            class="width-and-height mt8"
            :src="
              require('../../assets/img/guidePage/moments/member/memberFour.svg')
            "
          />
          <div class="moments-tag">
            <span class="moments-tag-content ml8">
              {{ tagList }}
            </span>
          </div>
        </div>
        <div class="business-step mt16 ml15 mr15">
          <customerTitle title="第5步" />
          <span class="text-class mt8">
            确认后跳转到“发表到客户的朋友圈”，点击“发表”
          </span>
          <img
            class="width-and-height mt8"
            :src="
              require('../../assets/img/guidePage/moments/member/memberFive.svg')
            "
          />
        </div>
        <div class="mt30 mb21 ml15 mr15">
          <van-button
            type="primary"
            size="large"
            @click="goDo(index)"
            :disabled="isStart === 0"
          >
            去完成
          </van-button>
        </div>
      </div>
      <div
        v-if="route.query.type == '5' && memberData.is_tag === 0"
        class="moments"
      >
        <div class="business-step mt16 ml15 mr15">
          <customerTitle title="第1步" />
          <span class="istag-text-class mt8">
            点击 “去完成” 跳转到 “发表到客户的朋友圈”，点击“发表”
          </span>
          <img
            class="width-and-height mt16"
            :src="
              require('../../assets/img/guidePage/moments/member/memberFive.svg')
            "
          />
        </div>
        <div class="mt60 mb21 ml15 mr15">
          <van-button
            type="primary"
            size="large"
            @click="goDo(index)"
            :disabled="isStart === 0"
          >
            去完成
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, onActivated } from 'vue'
import { useRoute } from 'vue-router'
import customerTitle from '../../components/customerTitle/index.vue'
import { getGuidePage } from '../../service/task.service.js'
import {
  openEnterpriseChat,
  openExistedChatWithMsg,
  shareToExternalMoments
} from '../../utils/nwechat'
import { checkIsFriend, checkIsGroup } from '../../service/wechat.service'
import moment from 'moment'

const route = useRoute()
// 接口参数
const params = ref({
  send_record_id: '',
  page: 1,
  page_size: 10,
  task_id: 0
})
// van-pull-refresh
const refreshing = ref(false)
// van-list
const finished = ref(false)
const loading = ref(false)
const error = ref(false)
// van-popover
const showPopover = ref(false)
// const actions = [{text: '因企微限制，其余 20 个客户需明日才可群发'}]

// 获取任务状态
const typeStatus = ref('')
// 数据存储
const memberData = ref([])
// 数据长度
const memberDataList = ref([])
// 是否是在未开始下，按钮需要置灰
const isStart = ref(0)
// 倒计时
const endTime = ref(0)
// 编译朋友圈图片等格式
const mediaList = ref([])
// 实时标签
const tagList = ref('')

// 手动刷新
const handleRefresh = () => {
  location.reload()
}

const onload = async () => {
  params.value.send_record_id = route.query.id
  if (route.query.task_id) {
    params.value.task_id = route.query.task_id
  } else {
    params.value.task_id = 0
  }
  if (memberDataList.value.length > 0) {
    params.value.page += 1
  }
  try {
    let { data } = await getGuidePage(params.value)
    memberData.value = data
    if (memberData.value.tag_list) {
      tagList.value = memberData.value.tag_list[0]
    }
    // memberDataList.value = data.list
    isStart.value = memberData.value.is_start
    endTime.value = Math.round(
      memberData.value.end_time_strtotime * 1000 - new Date()
    )
    // console.log('11111', endTime.value)
    // console.log(
    //   'h5datadata',
    //   JSON.stringify(memberData.value.annex_content_json)
    // )
    if (
      JSON.stringify(memberData.value.annex_content_json) != '[]' &&
      typeStatus.value == '5'
    ) {
      // 深拷贝数据
      let arr = JSON.parse(JSON.stringify(memberData.value.annex_content_json))
      handleMoments(arr)
    }

    if (data.list && data.list.length === 0) {
      loading.value = false
      finished.value = true
    }
    memberDataList.value = memberDataList.value.concat(data.list)
    loading.value = false
    if (
      data.list.length < params.value.page_size ||
      memberData.value.can_customer_num === memberDataList.value.length
    ) {
      finished.value = true
    }

    return Promise.resolve(true)
  } catch (err) {
    console.log(err)
  }
}
// const onRefresh = async() => {
//     memberDataList.value = []
//     refreshing.value = true

//     await this.onload()
//     refreshing.value = false
// }
// 去完成
const goDo = (index) => {
  console.log('goDo', index)
  if (typeStatus.value == '5' && memberData.value.touch_object == 'MOMENTS') {
    openUserChart()
  } else {
    memberDataList.value.forEach((e, i) => {
      if (index == i) {
        openUserChart(i)
      }
    })
  }
  //   console.log('endendend')
}

// 去完成任务打开会话
const openUserChart = async (index) => {
  //   console.log('11111111', memberDataList.value[index].receiver)
  // 1:成员单发;3:企业群发;4:企业群发到朋友圈;5:成员群发到朋友圈

  if (typeStatus.value == '1' && memberData.value.touch_object == 'SINGLE') {
    let { data } = await checkIsFriend({
      qw_userid: route.query.userIds,
      qw_external_userid: memberDataList.value[index].receiver
    })
    console.log(data)
    if (data.status) {
      // alert('当前客户好友关系')
      console.log('this.initEntry()')
      await openEnterpriseChat({
        userIds: route.query.userIds,
        externalUserIds: memberDataList.value[index].receiver
      })
      //   console.log('doit')
    } else {
      Dialog.alert({
        title: '提示',
        message: '当前客户为非好友关系，请通过其他方式联系客户。'
      })
    }
  }

  if (typeStatus.value == '1' && memberData.value.touch_object == 'GROUP') {
    let { data } = await checkIsGroup({
      qw_chat_id: memberDataList.value[index].receiver
    })
    console.log(data)
    if (data.status) {
      console.log('this.initEntry()')
      await openExistedChatWithMsg({
        chatId: memberDataList.value[index].receiver
      })
    } else {
      Dialog.alert({
        title: '提示',
        message: '当前客户群已不存在，请通过其他方式联系客户。'
      })
    }
  }

  if (typeStatus.value == '5' && memberData.value.touch_object == 'MOMENTS') {
    // console.log('555555555')
    await shareToExternalMoments({
      text: {
        content: memberData.value.content
      },
      attachments: mediaList.value
    })
  }
}
const handleMoments = (annexList) => {
  let arr = annexList
  console.log('arrarr', arr.length)
  for (let i = 0; i < arr.length; i++) {
    switch (arr[i].type) {
      case 'images': {
        mediaList.value[i] = {}
        mediaList.value[i].msgtype = 'image'
        mediaList.value[i].image = {}
        mediaList.value[i].image.imgUrl = arr[i].upload_url
        // mediaList.value[i].image.mediaid = arr[i].media_id
        break
      }
      case 'FILE': {
        mediaList.value[i] = {}
        mediaList.value[i].msgtype = 'link'
        mediaList.value[i].link = {}
        mediaList.value[i].link.title = arr[i].file_name
        mediaList.value[i].link.url = arr[i].file_url
        break
      }
      case 'web_page': {
        mediaList.value[i] = {}
        mediaList.value[i].msgtype = 'link'
        mediaList.value[i].link = {}
        mediaList.value[i].link.title = arr[i].title
        mediaList.value[i].link.url = arr[i].url
        mediaList.value[i].link.imgUrl = arr[i].upload_url
        break
      }
      case 'video': {
        mediaList.value[i] = {}
        mediaList.value[i].msgtype = 'video'
        mediaList.value[i].video = {}
        mediaList.value[i].video.mediaid = arr[i].media_id
        break
      }
    }
  }
  //   mediaList.value = arr
  console.log('mediaList', mediaList.value)
}

onload()
onMounted(async () => {
  typeStatus.value = route.query.type
  //   console.log('statusstatus', memberData.value)
  //   memberDataList.value.forEach((element) => {
  //     console.log('statusstatus', element.status)
  //   })
})
</script>

<style lang="less" scoped>
.guide-page-box {
  min-height: 100vh;
  background: #fff;
}
.width-and-height {
  width: 100%;
}
.text-content {
  font-weight: 400;
  line-height: 24px;
  font-size: 12px;
  color: #999999;
}

// 企业群发
.business-step {
  position: relative;
  .text-class {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #4a4a4a;
    line-height: 17px;
  }
  .istag-text-class {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #4a4a4a;
    line-height: 20px;
  }
  .business-three {
    position: absolute;
    top: 108px;
    left: 36px;
    line-height: 17px;
    font-size: 12px;
    font-weight: 500;
    color: #0c0c0c;
  }
  .business-two {
    position: absolute;
    top: 143px;
    left: 54px;
    line-height: 14px;
    font-size: 10px;
    font-weight: 400;
    color: #787878;
  }
}

// 成员单发
.member-Refresh {
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #1773fa;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.member-from {
  width: 268px;
  font-size: 14px;
  font-weight: 400;
  color: #171717;
  line-height: 20px;
}
.member-first {
  display: flex;
  position: relative;
  .member-first-box {
    display: inline-block;
    .member-fist-text {
      font-size: 12px;
      font-weight: 400;
      color: #4a4a4a;
      line-height: 17px;
    }
  }
  .member-img-col {
    display: block;
    width: 100%;
  }
  .member-img-circle {
    position: absolute;
    top: 7px;
    right: 82px;
  }
}
.member-second {
  display: flex;
  position: relative;
  .member-second-box {
    display: inline-block;
    .member-second-text {
      font-size: 12px;
      font-weight: 400;
      color: #4a4a4a;
      line-height: 17px;
    }
  }
  .member-img-col-two {
    display: block;
    width: 100%;
  }
  .member-img-circle-two {
    position: absolute;
    top: 13px;
    right: 7px;
  }
}
.member-task {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
  height: 44px;
  background: #f5f5f5;
  .task-info-item {
    font-size: 12px;
    color: #999999;
    font-weight: 400;
    height: 17px;
    line-height: 17px;
  }
  .member-send {
    display: inline-block;
    font-size: 14px;
    height: 16px;
    line-height: 16px;
    color: #999999;
    font-weight: 400;
  }
  .member-send-num {
    font-size: 14px;
    font-weight: 500;
    color: #1773fa;
    line-height: 17px;
  }
  .member-time-num {
    height: 17px;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
  }
  .member-task-time {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
  }
}
.member-send-task {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  .member-send-people {
    .member-send-task-img {
      width: 36px;
      height: 36px;
    }
    .member-send-task-name {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
    }
    .member-send-task-other-name {
      font-size: 12px;
      font-weight: 300;
      color: #999999;
      line-height: 16px;
    }
  }
  .member-button {
    display: block;
  }
  .member-send-group {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 24px;
  }
}

// 成员发朋友圈
.moments {
  .moments-member {
    width: 345px;
    font-size: 14px;
    font-weight: 400;
    color: #171717;
    line-height: 20px;
    .moments-send-target {
      color: #1773fa;
    }
  }
  .moments-send-text {
    // width: 110px;
    flex: 1;
    height: 79px;
    background: #ffffff;
    border: 1px solid #edeff2;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 400;
    color: #4a4a4a;
    line-height: 17px;
    text-align: center;
    .moments-already-send {
      font-size: 22px;
      font-weight: 500;
      color: #00c657;
      line-height: 30px;
    }
    .moments-member-send {
      font-size: 22px;
      font-weight: 500;
      color: #1773fa;
      line-height: 30px;
    }
  }
  .business-step-four {
    position: relative;
    .moments-tag {
      position: absolute;
      bottom: 40px;
      left: 28px;
      width: 227px;
      height: 24px;
      background: rgba(0, 0, 0, 0.04);
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 2px;
      .moments-tag-content {
        width: 211px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 24px;
      }
    }
  }
}
</style>
